<template>
  <div @click="beforeDownload" class="download-item flex-between">
    <template v-if="type === 'image'">
      <img class="prefix" src="../../assets/images/download-item/file_image.png"/>
    </template>
		<template v-else>
			<img class="prefix" src="../../assets/images/download-item/file_excel.png" v-if="fileName.indexOf('.xls') !== -1"/>
			<img class="prefix" src="../../assets/images/download-item/file_pdf.png" v-else-if="fileName.indexOf('.pdf') !== -1"/>
			<img class="prefix" src="../../assets/images/download-item/file_word.png" v-else-if="fileName.indexOf('.doc') !== -1"/>
			<img class="prefix" src="../../assets/images/download-item/file_ppt.png" v-else-if="fileName.indexOf('.ppt') !== -1"/>
		</template>
    <div class="clamp">
      <span>{{name}}</span>
    </div>
    <i class="el-icon-arrow-right append" style="color:rgb(168,169,170);"></i>
  </div>
</template>
<script>
export default {
  name: 'download-item',
  props: {
    type: {
      type: [Number, String],
      default: 'image'
    },
    name: {
      type: String
    },
    url: {
      type: String
    }
  },
  data() {
    return {
      filePath: ''
    }
  },
  computed: {
    fileName() {
      return this.name.toLowerCase()
    }
  },
  methods: {
    download() {
      const that = this
      if (this.type === 'image' || this.filePath) {
        this.previewFile(this.filePath)
        return
      }
      if (!this.url) {
        return
      }
      this.previewFile(this.url)
    },
    previewFile(filePath) {
      if (this.type === 'image') {
        this.$previewImage([filePath || this.url])
      } else {
        window.open(filePath)
      }
    },
    beforeDownload() {
      /* 不再重复下载 */
      if (this.type === 'image' || this.filePath) {
        this.previewFile(this.filePath)
        return
      }
      // uni.showModal({
      //   // title: '提示',
      //   content: '是否要下载 ' + this.name + ' 文件',
      //   success: res => {
      //     if (res.confirm) {
      this.download()
      //     } else {
      //       console.log('用户取消下载')
      //     }
      //   }
      // })
    }
  }
}
</script>
<style lang="scss">
  .download-item {
    background-color: #f9f9f9;
    border-radius: 6px;
    height: 42px;
    padding: 0 12px;
    font-size: 14px;

    &.flex-between {
      display: flex;
      align-items: center;
    }

    .prefix {
      width: 16px;
      height: 16px;

      &+.clamp {
        margin-left: 11px;
      }
    }

    .clamp {
      flex: 1;
      min-width: 0;
      margin-right: 11px;
      @include clamp(1);
    }
  }
</style>
