<template>
  <div class="ma-config reset-btn-page">
    <!--    <div class="head">-->
    <!--      <h2 class="title">关于{{pageTitle}}</h2>-->
    <!--    </div>-->
    <div class="content">
      <div class="flex" v-loading="show_loading">
        <div class="left">
          <el-form
            :model="form"
            :rules="rules"
            label-position="right"
            label-width="100px"
            ref="ma-form"
            size="medium"
          >
            <el-form-item label="名称：" prop="name">
              <el-input
                placeholder="请输入名称"
                size="medium"
                v-model="form.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="标题：" prop="title">
              <el-input
                placeholder="请输入标题"
                size="medium"
                v-model="form.title"
              ></el-input>
            </el-form-item>
            <el-form-item label="关键字：">
              <el-input
                placeholder="请输入关键字"
                size="medium"
                v-model="form.keyword"
              ></el-input>
            </el-form-item>
            <el-form-item label="描述：">
              <el-input
                placeholder="请输入描述"
                type="textarea"
                v-model="form.describe"
              ></el-input>
            </el-form-item>
            <el-form-item label="页面内容：" prop="content">
              <!-- <el-input v-model="form.textarea" type="textarea" :rows="28" placeholder="请输入内容"></el-input>-->
              <tinymce
                :height="550"
                @choose="handleToolBarClick"
                id="tinymce-single-page-editor"
                v-model="form.content"
              >
              </tinymce>
            </el-form-item>
            <el-form-item label="文件上传：" style="max-width: 440px">
              <el-upload
                :before-remove="beforeRemove"
                :before-upload="beforeUpload"
                :file-list="form.files"
                :http-request="handleUpload"
                :on-remove="handleRemove"
                action="#"
                multiple
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div class="el-upload__tip" slot="tip">
                  只能上传word、excel、pdf、jpg、png文件，且不超过150M
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="分享标题：">
              <el-input
                placeholder="请输入分享标题"
                size="medium"
                v-model="form.share_title"
              ></el-input>
            </el-form-item>
            <el-form-item label="分享图片：">
              <SingleMediaWall
                :height="140"
                :width="140"
                ratio="280:280"
                v-model="form.share_image"
              >
                <p class="img-tips" slot="info">建议尺寸为300*300</p>
              </SingleMediaWall>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <div class="el-form-item__label">预览：</div>
          <Phone :title="form.title">
<!--            <div class="phone-text" v-html="myContent"></div>-->

            <div class="phone-text">
              <tinymce
                :inline="true"
                :readonly="false"
                :show-article-import="false"
                :show-statusbar="false"
                :show-toolbar="false"
                ref="editorPreview"
                v-model="form.content"
              ></tinymce>
            </div>

            <!-- 文件下载 -->
            <div
              class="article-file-list"
              v-if="form.files && form.files.length"
            >
              <template v-for="(file, index) in form.files">
                <div :key="file.url" class="download-item-box">
                  <download-item
                    :name="file.title"
                    :type="file.type"
                    :url="file.url"
                  />
                </div>
              </template>
            </div>
          </Phone>
        </div>
      </div>
    </div>
    <!--    <image-select v-model="showImageList" :max="max" @handleChoose="updateImages"></image-select>-->
    <!--    <video-select v-model="showVideoList" :max="max" @choose="updateVideos"></video-select>-->
    <!--    <audio-select v-model="showAudioList" :max="max" @choose="updateAudios"></audio-select>-->
    <media-selector
      @select="updateImages"
      multiple
      v-model="showImageList"
    ></media-selector>
    <media-selector
      @select="updateVideos"
      multiple
      type="video"
      v-model="showVideoList"
    ></media-selector>
    <media-selector
      @select="updateAudios"
      multiple
      type="audio"
      v-model="showAudioList"
    ></media-selector>
    <!-- 文章导入 -->
    <import-article
      @confirm="getImportDetail"
      v-model="articleImport.show"
    ></import-article>
    <import-html-code v-model="isShowHtmlInput"></import-html-code>
    <fixed-action-bar :z-index="2">
      <el-button :loading="saveLoading" @click="onSave" type="primary"
        >保存</el-button
      >
      <!-- <el-button :loading="previewLoading" @click="onPreview">预览</el-button>-->
      <el-button @click="onCancel">取消</el-button>
    </fixed-action-bar>
  </div>
</template>
<script>
import { getDetail, getSave } from '../../api/cyc-content'
import FixedActionBar from '@/base/layout/FixedActionBar'
import MediaSelector from '../../../common/components/MediaSelector'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import Phone from '@/base/components/Preview/Phone'

import Tinymce from '@/base/components/Editor/Tinymce'
import ImportArticle from '@/base/components/Editor/ImportArticle'
import ImportHtmlCode from '@/base/components/Editor/ImportHtmlCode'
import { getFileType } from '../../../../base/utils/tool'
import filesUpload from '../../../../base/utils/upload4'
import DownloadItem from '../../components/download-item/download-item'

export default {
  data() {
    let validateText = (rule, value, callback) => {
      if (this.form.textarea === '') {
        callback(new Error('请输入介绍'))
      } else {
        callback()
      }
    }
    return {
      form: {
        name: '',
        content: '',
        title: '',
        keyword: '',
        share_title: '',
        share_image: '',
        describe: '',
        update_time: 0,
        id: '',
        files: [],
      },
      rules: {
        name: [{ required: true, message: '请输入名称' }],
        title: [{ required: true, message: '请输入标题' }],
        content: [
          { validator: validateText, trigger: 'blur' },
          { required: true, message: '请输入介绍', trigger: 'blur' },
        ],
      },
      saveLoading: false, //保存中
      previewLoading: false, //预览生成中
      openPreviewDialog: false, //显示预览弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      show_loading: false,
      articleImport: {
        show: false,
      },
      isShowHtmlInput: false,
    }
  },
  computed: {
    myContent: function () {
      if (!this.form || !this.form.content) return ''
      return this.form.content
        .replace(/\<img/gi, '<img class="max-width"')
        .replace(/<video/gi, '<video class="max-width"')
      // .replace(/<section/gi, '<section class="overflow-x"')
      // .replace(/<p/gi, '<p class="overflow-x"')
    },
  },
  created() {
    this.form.id = this.$route.params.id
    this.getCycDetail()
  },
  methods: {
    beforeRemove(file) {
      /* 不符合条件的文件无法上传，选择后会触发 beforeRemove 和 handleRemove */
      if (!this.beforeUpload(file, true)) return
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove(file, fileList) {
      this.form.files = fileList
    },
    /**
     * @param file
     * @param silent  是否关闭错误提示
     * */
    beforeUpload: function (file, silent = false) {
      /* 文件类型判断 */
      const myFileType = getFileType(file?.name)
      const fileTypeSupported = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'jpg',
        'jpeg',
        'png',
      ].includes(myFileType)
      // 大小判断
      const is10M = file.size / 1024 / 1024 < 150
      if (!fileTypeSupported && !silent) {
        this.$message.error('上传文件类型仅支持 word、excel、pdf、jpg、png !')
      } else if (!is10M && !silent) {
        // 大小不匹配
        this.$message.error('上传文件大小不能超过 150MB !')
      }
      // 返回 false 阻断 true 正常上传
      return is10M && fileTypeSupported
    },
    handleUpload(file) {
      /* 上传成功后才将{name: '', url: ''}push到fileList中，会有成功图标 */
      // this.fileList.push({name: file.file.name, url: ''})
      filesUpload({
        configApi: '/admin/admin/media/requestUpload',
        data: file.file,
        progress: (n) => {
          // 更新 el-upload file-list 自带的进度条数值
          file.onProgress({ percent: n })
        },
      })
        .then((res) => {
          this.form.files.push({ ...res.data, name: file.file.name })
        })
        .catch(() => {})
    },
    getImportDetail(e) {
      // window.tinymce
      //   .get("tinymce-single-page-editor")
      //   .execCommand("mceReplaceContent", false, e.content + "<br/>");
      this.form.content = e.content + '<br />'
    },
    getCycDetail() {
      if (this.form.id != 0) {
        this.show_loading = true
        getDetail({ id: this.form.id })
          .then((res) => {
            this.form = res.data
            this.form.files = res.data.files.map((f) => ({
              ...f,
              name: f.title,
            }))
            this.show_loading = false
          })
          .catch((err) => {
            this.show_loading = false
          })
      } else {
        this.show_loading = false
      }
    },
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<img src="${item.url}"/>`
        )
      })
      this.showImageList = false
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
        )
      })
      this.showVideoList = false
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        )
      })
      this.showAudioList = false
    },
    //选择图片
    chooseImages() {
      this.showImageList = true
    },
    //选择视频
    chooseVideos() {
      this.showVideoList = true
    },
    //选择音频
    chooseAudios() {
      this.showAudioList = true
    },
    // 富文本框点击图片，视频，音频返回事件
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.chooseImages()
          break
        case 'video':
          this.chooseVideos()
          break
        case 'audio':
          this.chooseAudios()
          break
        case 'import':
          this.articleImport.show = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    //保存
    onSave() {
      // if (this.form.title === "" || this.form.textarea === "") {
      //   this.$message({
      //     message: "标题和介绍内容不能为空",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.$refs['ma-form'].validate((valid) => {
        if (valid) {
          this.doSave()
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({ block: 'center', behavior: 'smooth' })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    doSave() {
      this.show_loading = true
      getSave({
        ...this.form,
        files: this.form.files.map((el) => el.id),
      })
        .then((res) => {
          this.$message({
            message: '保存成功',
            type: 'success',
          })
          this.$router.go(-1)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.show_loading = false
        })
    },

    //取消
    onCancel() {
      this.$router.go(-1)
    },
  },
  components: {
    DownloadItem,
    ImportHtmlCode,
    ImportArticle,
    MediaSelector,
    FixedActionBar,
    Tinymce,
    SingleMediaWall,
    Phone,
  },
}
</script>
<style lang="scss" scoped>
.ma-config {
  margin: -$space;

  .head {
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
    }
  }

  .content {
    display: flex;
    min-width: 1080px;

    .left,
    .right {
      background-color: white;
      padding: $space;
      border-radius: 5px;
    }

    .left {
      flex: 1;

      .el-form {
        max-width: 800px;
      }
    }

    .right {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .phone-text {
        padding-left: 12px;
        padding-right: 12px;
        /* 针对textarea标签输入的文本 */
        /*white-space: pre-wrap;*/
        /*word-wrap: break-word;*/
        /*word-break: break-all;*/
      }
    }

    .item {
      & + .item {
        margin-top: 15px;
      }

      .label {
        font-size: 16px;
        line-height: 22px;
        color: rgba(33, 33, 33, 1);
        margin-bottom: 8px;

        &::before {
          content: '*';
          color: #f56c6c;
          margin-right: 4px;
        }
      }
    }
  }
  .img-tips {
    text-align: center;
    font-size: 12px;
    color: #c0c4cc;
    line-height: 24px;
  }
}

.article-file-list {
  padding: 20px 12px;

  .download-item-box + .download-item-box {
    margin-top: 12px;
  }
}
</style>
